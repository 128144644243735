<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="7"
        xl="8"
        :style="`background-image:url('${leftBgImg}')`"
        class="d-none d-lg-flex align-items-center p-5 sign-in--left-col"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <!--
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
          -->
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="5"
        xl="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto brand-logo-col"
        >

          <!-- Brand logo-->
          <b-link class="brand-logo">
            <vuexy-logo />
          </b-link>
          <!-- /Brand logo-->
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome!
            <!--  Welcome to {{
              themeConfig.app.appName
            }}! -->
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  ref="email"
                  name="Email"
                  vid="email"
                  mode="lazy"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model.trim="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="remember"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="isSubmitting"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'signup'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import AuthService from '@/services/AuthService'
import { handleValidationError } from '@/utils/helpers'
import { $themeConfig } from '@themeConfig'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isSubmitting: false,
      remember: false,
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      leftBgImg: require('@/assets/images/signin.jpg'),
      required,
      email,
      baseToastTimeout: 2000,
      themeConfig: $themeConfig,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint`-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    AuthService.logout()
  },
  methods: {
    async login() {
      this.$refs.loginForm.validate()
        .then(async success => {
          if (success) {
            this.isSubmitting = true
            const payload = {
              email: this.userEmail,
              password: this.password,
              remember: this.remember,
            }
            this.error = null
            try {
              await AuthService.login(payload)
                .then(async () => {
                  await store.dispatch('auth/getAuthUser')
                  const authUser = store.getters['auth/authUser']
                  if (authUser) {
                    // NOTE: we're suppressing possible navigation errors that would be
                    // reported by the router in case of forced redirect
                    this.$router.push({ name: 'home' })
                      .catch(() => {
                      })
                  } else {
                    const error = Error(
                      'Unable to fetch user after login, check your API settings.',
                    )
                    error.name = 'Fetch User'
                    throw error
                  }
                }).catch(error => {
                  handleValidationError(error, this)
                })
            } catch (error) {
              handleValidationError(error, this)
              console.error(Object.keys(error))
            } finally {
              this.isSubmitting = false
            }
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.brand-logo-col .brand-logo{
  position: relative !important;
  left: auto !important;
  margin-left: 0;
  display: flex;
  justify-content: center !important;
  top: auto !important;
  margin-bottom: 2rem !important;
  margin-right: 0;
  padding-right: 0;
  width: 100%;
  max-width: 100%;
}
.sign-in--left-col{
 position:relative;
  background-size:cover;
  background-position: center center;
}
.sign-in--left-col:before{
  content:'';
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgba(255,255,255,0.5);
}
</style>
<style scoped>
@media screen and (max-width: 991px){
  .auth-bg{
    padding-top: 3rem !important;
    align-items: flex-start !important;
  }
}
</style>
